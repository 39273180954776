<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on"> Upload </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">Upload Excel </span>
      </v-card-title>
      <v-form ref="uploadForm" lazy-validation @submit.prevent="handleUpload">
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12">
                <v-file-input
                  :rules="rules"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  placeholder="Select Excel"
                  prepend-icon="mdi-file"
                  label="Select Excel *"
                  show-size
                  required
                  v-model="selectedFile"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="handleUpload">
            Upload
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import * as XLSX from "xlsx";
import Api from "@/objects/api";
import Service from "@/objects/service";
import { mapState } from "vuex";

export default {
  name: "BreadTabulatorUpload",
  data: () => ({
    dialog: false,
    rules: [
      (value) => {
        return (
          !value ||
          !value.length ||
          value[0].size < 20000000 ||
          "Excel size should be less than 20 MB!"
        );
      },
    ],
    selectedFile: null,
    excelHeaders: [],
    excelRecords: [],
    service: new Service(),
    api: new Api(),
  }),

  computed: mapState({
    services: (state) => state.service.data.services,
  }),

  props: {
    model: {
      type: Object,
      required: true,
    },
  },

  created() {
    this.excelHeaders = this.$_.filter(this.model.add.fields, (columns) => {
      return columns;
    });

    //BOC:[service]
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);
    //EOC
  },

  methods: {
    handleUpload() {
      if (!this.selectedFile) {
        return;
      }
      if (this.$refs.uploadForm.validate()) {
        // Perform the upload logic here
        /* console.log('Uploading file:', this.selectedFile); */
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "binary" });
          // Get first worksheet
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          // Convert array of arrays
          const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
          //const htmlTable = XLSX.utils.sheet_to_html(sheet, { header: 1 });
          //this.jsonData = JSON.stringify(jsonData, null, 2);

          // check excel exccel headers
          for (let i = 0; i < this.excelHeaders.length; i++) {
            if (this.excelHeaders[i].key !== jsonData[0][i]) {
              this.$store.dispatch(
                "showMessage",
                "Excel headers does not match!"
              );
              return;
            }
          }
          const excelRecords = [];
          for (let j = 1; j < jsonData.length; j++) {
            let data = {};
            for (let k = 0; k < this.excelHeaders.length; k++) {
              if (this.excelHeaders[k].type == "date") {
                data[this.excelHeaders[k].key] = new Date(jsonData[j][k]);
                continue;
              }

              if (this.excelHeaders[k].type == "number") {
                data[this.excelHeaders[k].key] = parseInt(jsonData[j][k], 10);
                continue;
              }

              if (this.excelHeaders[k].type == "float") {
                data[this.excelHeaders[k].key] = parseFloat(jsonData[j][k]);
                continue;
              }

              data[this.excelHeaders[k].key] = jsonData[j][k];
            }
            excelRecords.push(data);
            this.excelRecords = excelRecords;
            //this.$store.dispatch("showMessage", "Excel uploaded successfully!");
            this.dialog = false;
          }

          //console.log(this.excelRecords);
          this.insertDatatoTable(this.excelRecords);
        };

        reader.readAsBinaryString(this.selectedFile);
      }

      this.selectedFile = null;
    },

    insertDatatoTable(records) {
      this.api.setMethod(`POST`);

      this.api.setParams({
        modelKey: this.model.key,
        data: JSON.stringify(records),
      });

      this.api.setUrl(
        `${this.$service[this.service.key]}/v1/en/console/tabulator/model/${
          this.model.key
        }/upload`
      );

      this.api.setCallbackCompleted(() => {
        this.$store.dispatch("showMessage", {
                    message: "Updated successfully.",
                    messageType: "success",
                    timeout:2000
                });
        this.$router.go();
      });

      this.api.fetch();
    },
  },
};
</script>