<template >
  <v-container fluid class="tb-layout-browse">
    <ATabulatorLoder :isLoading="isLoading" />
    <!---BOC:[breadcrumb]-->
    <ABreadcrumb :items="breadcrumbTabulator.items" />
    <!--EOC-->
    <!---BOD:[error]-->
    <AError :api="apiThatReturnedError"/>
    <!--EOC-->

    <div v-if="this.rolePermission[`${model.permissionKeys.browse}`].isAllowed">
      <BreadHeader :title="model.name.plural">
        <v-btn
          :disabled="
            !model.add ||
            !this.rolePermission[`${model.permissionKeys.add}`].isAllowed
          "
          color="primary"
          :to="{
            name: 'PageServiceModelBreadTabulatorAdd',
            params: {
              serviceKey: model.serviceKey,
              modelKey: model.key,
            },
          }"
          >+ New {{ model.name.singular }}</v-btn
        >

        <LeadAssignToInternalUsers
        v-if="model.serviceKey == 'lead'"
        ref="leadAssignToInternalUsers"
        :model="model"
        :selectedRows="selectedRows"
        :disabledStatus="this.rolePermission[`${model.permissionKeys.add}`].isAllowed"
      >
      </LeadAssignToInternalUsers>

      </BreadHeader>

      

      <v-row class="tb-filter-section">
        <v-row no-gutters>
          <v-col md="4">
            <div class="tb-left-second-section">
              <div>
                <h5
                  v-if="switchToAdvanceFilter == true"
                  @click="switchToAdvanceFilter = false"
                  style="cursor: pointer"
                >
                  Switch to Normal Filter
                </h5>
                <h5
                  v-if="switchToAdvanceFilter == true"
                  @click="switchToAdvanceFilter = true"
                  style="cursor: pointer"
                >
                  Switch to Advance Filter
                </h5>
              </div>
              <div>
                <h4 v-on:click="isShow = !isShow" style="float: left">
                  Filter
                  <v-icon left> mdi mdi-menu-down </v-icon>
                </h4>
                <span v-if="switchToAdvanceFilter == false">
                  {{ conditions.length }} Filter Applied
                </span>
              </div>
            </div>
          </v-col>
          <v-col md="8">
            <!-- include column selection compnent -->

            <BreadTabulatorSelectColumn
              ref="selectColumn"
              :model="model"
              @applyColumnSelection="applyColumnSelection"
            ></BreadTabulatorSelectColumn>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-2">
          <v-col md="12">
            <div class="tb-normal-filter" v-show="isShow">
              <div
                class="tb-filter-area"
                v-show="switchToAdvanceFilter == false"
              >
                <BreadTabulatorFilterable
                  ref="filter"
                  :model="model"
                  :setConditions="setConditions"
                ></BreadTabulatorFilterable>
              </div>

              
            </div>
          </v-col>
        </v-row>
      </v-row>

      <!-- defult search area-->
      <div class="tb-search-area">
        <v-row>
          <v-col md="6">
            <BreadTabulatorSearchable
              ref="search"
              :model="model"
              :setConditions="setConditions"
            ></BreadTabulatorSearchable>
            <div>
              <span
                id="record-count"
                style="float: left; font-size: 12px"
              ></span>
            </div>
          </v-col>

          <v-col md="6" align="right">
            <v-row no-gutters>
              <v-col md="4">
                <div
                  v-if="
                    this.allowCellEditOption &&
                    this.rolePermission[`${model.permissionKeys.edit}`]
                      .isAllowed
                  "
                >
                  <v-btn
                    @click="setIsEditableCell(true)"
                    v-if="this.cellEditableStatus == true"
                    >Enable Cell Edit</v-btn
                  >
                  <v-btn
                    @click="setIsEditableCell(false)"
                    v-if="this.cellEditableStatus == false"
                    >Disable Cell Edit</v-btn
                  >
                </div>
              </v-col>
              <v-col md="8">
                <v-btn-toggle dense multiple class="mr-1">
                  
                  <BreadTabulatorUpload
                    ref="excelUpload"
                    :model="model"
                  ></BreadTabulatorUpload>
                  <!-- have to include data download component-->
                  <BreadTabulatorExport
                    ref="exportData"
                    :model="model"
                    :apiUrl="this.apiGetBrowseUrl()"
                    @downloadData="downloadData"
                  >
                  </BreadTabulatorExport>
                </v-btn-toggle>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>

      <!-- table area with tabs-->

      <BreadTabulatorViewLatest
        ref="customView"
        :model="model"
        :selectedCustomColums="this.selectedCustomColums"
        :selectedAdvanceFilterConditions="this.selectedAdvanceFilterConditions"
        :selectedFilterConditions="this.selectedFilterConditions"
        :cColumnSort="this.cColumnSort"
        :displayRowCount="this.displayRowCount"
        :listAllViews="this.listAllViews"
        :allResizeColumns="this.allResizeColumns"
        @changeTableDetails="changeTableDetails"
      ></BreadTabulatorViewLatest>

      <BreadTabulator
        ref="tabulatorDataTable"
        layout="fitColumns"
        :autoColumns="false"
        :paginationSize="this.paginationSize"
        :paginationSizeSelector="this.paginationSizeSelector"
        :apiUrl="this.apiGetBrowseUrl()"
        :columns="this.columns"
        :modelKey="this.modelKey"
        :columnSelectionDisabledCount="this.columnSelectionDisabledCount"
        @updateEditedCellData="updateEditedCellData"
        @getCurrentPageSize="getCurrentPageSize"
        @getCurrentSort="getCurrentSort"
        @getCurrentColumnWidth="getCurrentColumnWidth"
        @getCurrentPageNumber="getCurrentPageNumber"
        @getSelectedRows="getSelectedRows"
        @getDataLoadErrorCode = "getDataLoadErrorCode"
      ></BreadTabulator>
    </div>
    <div v-else>
      <v-row>
        <v-col md="12">
          <v-alert
            type="error"
            dismissible
            border="left"
            elevation="2"
            icon="mdi-alert"
          >
            You don't have permission to browse this model.
          </v-alert>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
  
  <script>
//BOC:[api]
import Api from "@/objects/api";
//EOC
//BOC:[breadcrumb]
import BreadcrumbTabulator from "@/objects/breadcrumbTabulator";
//EOC
//BOC:[model]
import Model from "@/objects/model";
//EOC
//BOC:[service]
import Service from "@/objects/service";
//EOC

/*   import {DateTime} from "luxon"; */

import BreadTabulatorUpload from "../../../components/common/BreadTabulatorUpload.vue";
import LeadAssignToInternalUsers from "../../../components/common/LeadAssignToInternalUsers.vue"; 

import { mapState } from "vuex";
export default {
  components: {
    BreadTabulatorUpload,
    LeadAssignToInternalUsers,
  },
  computed: mapState({
    auth: (state) => state.auth.data,
    services: (state) => state.service.data.services,
    rolePermission: (state) => state.rolePermission.data,
    team: (state) => state.team.data,
  }),
  props: [
    //
  ],
  data: () => ({
    //BOC:[api]
    api: new Api(),
    //EOC
    //BOC:[apiTrash]
    apiTrash: new Api(),
    //EOC
    apiDropdown: new Api(),
    //BOC:[apiDeleteMultiRecords]
    apiMangeTimeLine: new Api(),
    //EOC
    //BOC:[breadcrumb]
    breadcrumbTabulator: new BreadcrumbTabulator(),
    //EOC

    apiTeam: new Api(),

    //BOC:[model]
    model: new Model(),
    //EOC
    //BOC:[listAllViews]
    listAllViews: [],
    //EOC

    dataStatus: "active",

    //BOC:[tools]
    tools: {
      advancedBar: [2, 3],
      enableColumnSelection: true,
      enableRowSelection: true,
      enableSearch: true,
      showAdvancedBar: true,
    },
    //EOC
    //BOC:[filters]
    filters: [],
    //EOC
    //BOC:[service]
    service: new Service(),
    //EOC
    selectedRows: [],
    records: [],
    //BOC:[conditions]
    conditions: [],
    //EOC
    selectedCustomColums: [],
    selectedAdvanceFilterConditions: [],
    selectedFilterConditions: [],
    paginationSizeSelector: [10, 50, 100],
    paginationSize: 10,
    columns: [],
    userRole: "admin",
    modelKey: "",
    parentId: "",
    switchToAdvanceFilter: false,
    isShow: false,
    cColumnSort: [],
    displayRowCount: 10,
    isLoading: false,
    allResizeColumns: [],
    cellEditableStatus: true,
    viewId: "",
    columnSelectionDisabledCount: 2,
    currentPageNo: 1,
    allowCellEditOption: true,
    apiThatReturnedError: '',
    apiBrowseUrl: "",
    apiTrashedBrowseUrl: "",
    apiMultiUpdateUrl: "",
    isActionColumnVisible: true,
    browseAll: false,
    browseOwn: false,
    browseTeam: false,

    recordBrowseAll : false,
    recordBrowseAllApiUrl : "",
    recordBrowseOwn : false,
    recordBrowseOwnApiUrl : "",
    recordBrowseAssignedTo : false,
    recordBrowseAssignedToApiUrl : "",
    recordBrowseBelongsTo : false,
    recordBrowseBelongsToApiUrl : "",
    dataLoadErrorCode : '',

  }),
  watch: {
   //
  },
  created() {
    //BOC:[model]
    this.model.getByKey(this.$route.params.modelKey);
    //this.model.getByKey("demoProduct");
    //EOC

    //BOC:[modelKey]
    this.modelKey = this.$route.params.modelKey;
    //EOC: [modelKey]

    //BOC:[parentId]
    this.parentId = this.$route.params.parentId;
    //EOC:[parentId]

    //BOC:[viewId]
    this.viewId = this.$route.query.viewId;
    //EOC:[viewId]

    //EOC [table header columns]
    this.columns = this.$_.filter(
      this.model.browse.table.headers,
      (headers) => {
        return headers;
      }
    );
    //BOC:[table header columns]

    //BOC:[cell edit option]
    this.allowCellEditOption = this.model.browse.table.actions.manageCellEdit;
    //EOC:[cell edit option]

    //BOC:[column selection disabled count]
    this.columnSelectionDisabledCount =
      this.model.browse.table.actions.columnSelectionDisabledCount;
    //EOC:[column selection disabled count]

    //BOC:[API Browse Url]
    this.apiBrowseUrl = this.model.apiUrls.apiBrowseUrl;
    //EOC [API Browse Url]

    //BOC:[API Trashed Browse Url]
    this.apiTrashedBrowseUrl = this.model.apiUrls.apiTrashedBrowseUrl;
    //EOC [API Trashed Browse Url]

    //BOC:[API Multi Update Url]
    this.apiMultiUpdateUrl = this.model.apiUrls.apiMultiUpdateUrl;
    //EOC [API Multi Update Url]

    //BOC:[mange action column]
    this.isActionColumnVisible =
      this.model.browse.table.actions.isActionColumnVisible;
    //EOC [mange action column]

    //BOC:[method to manage role based functionality for browse]
    this.manageRoleBasedFunctionalityForBrowse();
    //EOC [method to manage role based functionality for browse]

    this.manageRoleBasedRecordAccessControll();

    var columnIndex = 0;
    for (var column of this.columns) {
      //BOC: [convert format of columns with dataType = modelParent]
      if (column.dataType == "modelParent") {
        /* var parentColumnName = column.field; */
        var parentModel = new Model();
        parentModel.getByKey(column.modelKey);
        this.columns[columnIndex].formatter = (cell) => {
          var parent = cell.getRow().getData()[this.$_ucfirst(parentModel.key)];
          var parentLabel = parent.id;
          if (parent.name) parentLabel = parent.name;
          if (parentModel.column && parentModel.column.text)
            parentLabel = parent[parentModel.column.text];

          return `<span style="color:blue;">${parentLabel}</span>`;
        };

        this.columns[columnIndex].accessorDownload = (e, data) => {
          return data[this.$_ucfirst(parentModel.key)].name;
        };

        this.columns[columnIndex].cellClick = (e, cell) => {
          var parent = cell.getRow().getData()[this.$_ucfirst(parentModel.key)];
          var parentLabel = parent.id;
          this.$router.push({
            name: "PageServiceModelBreadTabulatorRead",
            params: {
              serviceKey: parentModel.serviceKey,
              modelKey: parentModel.key,
              modelId: parentLabel,
              /* childModelKey: this.model.key, */
            },
          });
        };
      }

      //EOC: [convert format of columns with dataType = modelParent]

      //BOC: [convert format of columns with dataType = date]

      if (column.dataType == "date") {
        this.columns[columnIndex].formatter = (cell) => {
          var exDate = cell.getValue();
          if (exDate) {
            /* return DateTime.local(date).toLocaleString(DateTime.DATE_FULL); */
            return this.$moment(new Date(exDate)).format("YYYY-MM-DD");
          }
        };
      }

      //EOC: [convert format of columns with dataType = date]

      columnIndex++;
    }

    //BOC: [Action Column]
/* 
    this.columns.push({
      title: "Action",
      filterable: false,
      headerSort: false,
      formatter: (cell) => {
        var timestampDeleted = cell.getRow().getData().timestampDeleted;
        var readPermission =
          this.rolePermission[`${this.model.permissionKeys.read}`].isAllowed;
        var deletePermission =
          this.rolePermission[`${this.model.permissionKeys.delete}`].isAllowed;
        var restorePermission =
          this.rolePermission[`${this.model.permissionKeys.restore}`].isAllowed;
        if (timestampDeleted) {
          if (restorePermission && deletePermission) {
            return '<button class="restore-btn btn-primary">Restore</button> <v-btn class="delete-hard-btn btn-delete-hard">Delete Hard</v-btn>';
          }
          if (restorePermission) {
            return '<button class="restore-btn btn-primary">Restore</button>';
          }
          if (deletePermission) {
            return '<button class="delete-hard-btn btn-delete-hard">Delete Hard</button>';
          }
        } else if (readPermission) {
          return '<button class="read-btn btn-primary">Read</button>';
        }
      },

      cellClick: (e, cell) => {
        var timestampDeleted = cell.getRow().getData().timestampDeleted;
        if (timestampDeleted) {
          if (e.target.classList.contains("restore-btn")) {
            this.$router.push({
              name: "PageServiceModelBreadTabulatorRestore",
              params: {
                serviceKey: this.model.serviceKey,
                modelKey: this.model.key,
                modelId: cell.getRow().getData().id,
              },
            });

            return;
          }

          if (e.target.classList.contains("delete-hard-btn")) {
            this.$router.push({
              name: "PageServiceModelBreadTabulatorDeleteHard",
              params: {
                serviceKey: this.model.serviceKey,
                modelKey: this.model.key,
                modelId: cell.getRow().getData().id,
              },
            });
            return;
          }
        }

        this.$router.push({
          name: "PageServiceModelBreadTabulatorRead",
          params: {
            serviceKey: this.model.serviceKey,
            modelKey: this.model.key,
            modelId: cell.getRow().getData().id,
          },
          query: {
            viewId: this.viewId,
            currentPageNo: this.currentPageNo,
          },
        });
      },
      download: false,
      width: 200,
      visible: this.isActionColumnVisible,
    }); */

    //EOC: [Action Column]

    //BOC:[service]
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);
    //EOC

    //BOC:[tools]
    if (this.model.browse.tools) this.tools = this.model.browse.tools;
    //EOC

    //BOC:[breadcrumb]
    this.breadcrumbTabulator.addForOperationBrowse({
      model: this.model,
      services: this.services,
    });
    //EOC

  },
  mounted() {
    //BOC:[get all views]
    const viewId = this.$route.query.viewId;

    if (viewId !== "all" && viewId !== "trashed") {
      this.getAllViews(parseInt(viewId, 10));
    } else {
      this.getAllViews(viewId);
    }
    //EOC:[get all views]

  },
  methods: {

    //BOC:[ get active data and fetch with tabulator based on the user role]
    //http://localhost:8001/api/v1/en/console/tabulator/model/ssoTeam/browse/all?page=1&size=10
    apiGetBrowseUrl() {

      

       var userUuid = this.auth.uuid;
       var teamUuid = this.team;

      if (this.recordBrowseAll) {
        if (this.recordBrowseAllApiUrl != "") {
          return `${this.$service[this.service.key]}${this.recordBrowseAllApiUrl}`;
        }else{
            return `${this.$service[this.service.key]}/v1/en/console/tabulator/model/${
            this.model.key}/browse/all`;
        }
      }

      if (this.recordBrowseOwn) {
        if(this.recordBrowseOwnApiUrl != ""){
          return `${this.$service[this.service.key]}${
            this.recordBrowseOwnApiUrl
          }/${userUuid}`;
        }else{
          return `${this.$service[this.service.key]}/v1/en/console/tabulator/model/${
            this.model.key}/browse/all`;
        }
      }

      if (this.recordBrowseAssignedTo) {
        if (this.recordBrowseOwnApiUrl != "") {
          return `${this.$service[this.service.key]}${
            this.recordBrowseOwnApiUrl
          }/${userUuid}`;
        }else{
          return `${this.$service[this.service.key]}/v1/en/console/tabulator/model/${
            this.model.key}/browse/all`;
        }
      }

      if (this.recordBrowseBelongsTo) {
        if (this.recordBrowseBelongsToApiUrl != "") {
          return `${this.$service[this.service.key]}${
            this.recordBrowseBelongsToApiUrl
          }/${teamUuid}/listByTeamUuid/${this.auth.uuid}`;
        }else{
          return `${this.$service[this.service.key]}/v1/en/console/tabulator/model/${
            this.model.key}/browse/all`;
        }
      }

      /* if (this.browseAll) {
        if (this.apiBrowseUrl != "") {
          return `${this.$service[this.service.key]}${this.apiBrowseUrl}`;
        }
        return `${this.$service[this.service.key]}/v1/en/console/tabulator/model/${
          this.model.key
        }/browse/all`;
      }

      if (this.browseOwn) {
        var userUuid2 = this.auth.uuid;
        if (this.apiBrowseUrl != "") {
          return `${this.$service[this.service.key]}${
            this.apiBrowseUrl
          }/${userUuid2}`;
        }

        return `${this.$service[this.service.key]}/v1/en/console/tabulator/model/${
          this.model.key
        }/browse/all`;
      }

      if (this.browseTeam) {
        if (this.apiBrowseUrl != "") {
          var teamUuid = this.team;
          return `${this.$service[this.service.key]}${
            this.apiBrowseUrl
          }/${teamUuid}/listByTeamUuid/${this.auth.uuid}`;
        } else {
          return `${this.$service[this.service.key]}/v1/en/console/tabulator/model/${
          this.model.key
        }/browse/all`;
        }
      } */
    },

    //EOC:[ get active data and fetch with tabulator based on the user role]

    //BOC:[ get trashed data and fetch with tabulator based on the user role]

    apiGetBrowseTrashUrl() {

        var userUuid = this.auth.uuid;
        var teamUuid = this.team;


        if (this.recordBrowseAll) {
        if (this.apiTrashedBrowseUrl != "") {
          return `${this.$service[this.service.key]}${this.apiTrashedBrowseUrl}`;
        }else{
            return `${this.$service[this.service.key]}/v1/en/console/tabulator/model/${
        this.model.key}/browse/trashed`;
        }
      }

      if (this.recordBrowseOwn) {
        if(this.recordBrowseOwnApiUrl != ""){
          return `${this.$service[this.service.key]}${
            this.apiTrashedBrowseUrl
          }/${userUuid}`;
        }else{
          return `${this.$service[this.service.key]}/v1/en/console/tabulator/model/${
        this.model.key}/browse/trashed`;
        }
      }

      if (this.recordBrowseAssignedTo) {
        if (this.recordBrowseOwnApiUrl != "") {
          return `${this.$service[this.service.key]}${
            this.apiTrashedBrowseUrl
          }/${userUuid}`;
        }else{
          return `${this.$service[this.service.key]}/v1/en/console/tabulator/model/${
        this.model.key}/browse/trashed`;
        }
      }

      if (this.recordBrowseBelongsTo) {
        if (this.recordBrowseBelongsToApiUrl != "") {
          return `${this.$service[this.service.key]}${
            this.apiTrashedBrowseUrl
          }/${teamUuid}/listByTeamUuid/${this.auth.uuid}`;
        }else{
          return `${this.$service[this.service.key]}/v1/en/console/tabulator/model/${
        this.model.key}/browse/trashed`;
        }
      }

     /*  if (this.browseAll) {
        if (this.apiTrashedBrowseUrl != "") {
          return `${this.$service[this.service.key]}${this.apiTrashedBrowseUrl}`;
        }
        return `${this.$service[this.service.key]}/v1/en/console/tabulator/model/${
        this.model.key}/browse/trashed`;
      }

      if (this.browseOwn) {
        var userUuid = this.auth.uuid;

        if (this.apiTrashedBrowseUrl != "") {
          return `${this.$service[this.service.key]}${
            this.apiTrashedBrowseUrl
          }/${userUuid}`;
        }
        return `${this.$service[this.service.key]}/v1/en/console/tabulator/model/${
        this.model.key}/browse/trashed`;
      }

      if (this.browseTeam) {
        if (this.apiTrashedBrowseUrl != "") {
          var teamUuid = this.team;
          return `${this.$service[this.service.key]}${
            this.apiTrashedBrowseUrl
          }/${teamUuid}/listByTeamUuid/${this.auth.uuid}`;
        } else {
          return `${this.$service[this.service.key]}/v1/en/console/tabulator/model/${
        this.model.key}/browse/trashed`;
        }
      } */

    },

    //EOC:[ get trashed data and fetch with tabulator based on the user role]

    //BOC:[ method to manage role based functionality for browse]
    manageRoleBasedFunctionalityForBrowse() {
      var roleBasedFunctionalityBrowse =
        this.model.roleBasedFunctionalities.browse;
      var userRoles = this.auth.roles.map((role) => {
        return role.slug;
      });

      for (var role of userRoles) {
        if (roleBasedFunctionalityBrowse.all.includes(role)) {
          this.browseAll = true;
          this.browseOwn = false;
          this.browseTeam = false;
        }

        if (roleBasedFunctionalityBrowse.own.includes(role)) {
          this.browseAll = false;
          this.browseOwn = true;
          this.browseTeam = false;
        }

        if (roleBasedFunctionalityBrowse.team.includes(role)) {
          this.browseAll = false;
          this.browseOwn = false;
          this.browseTeam = true;
        }
      }
    },
    //manage records based on the user role

    manageRoleBasedRecordAccessControll(){
      var roleBasedRecordAccessControll = this.model.recordAccessControl.browse;

      var manageAll = roleBasedRecordAccessControll.all;
      var manageAssignedTo = roleBasedRecordAccessControll.assignedTo;
      var manageBelongsTo = roleBasedRecordAccessControll.belongsTo;
      var manageOwn = roleBasedRecordAccessControll.own;
      //console.log('manageAll', manageAll, 'manageAssignedTo', manageAssignedTo, 'manageBelongsTo', manageBelongsTo, 'manageOwn', manageOwn);
      var userRoles = this.auth.roles.map((role) => {
        return role.slug;
      });

      // check user role has manageAll access roles
      for(var role of userRoles){
        if(manageAll.roles.includes(role)){
            this.recordBrowseAll = true;
            this.recordBrowseAllApiUrl = manageAll.apiUrl;
        }

        if(manageAssignedTo.roles.includes(role)){
            this.recordBrowseAssignedTo = true;
            this.recordBrowseAssignedToApiUrl = manageAssignedTo.apiUrl;
        }

        if(manageBelongsTo.ssoTeam.roles.includes(role)){
            this.recordBrowseBelongsTo = true;
            this.recordBrowseBelongsToApiUrl = manageBelongsTo.ssoTeam.apiUrl;
        }

        if(manageOwn.roles.includes(role)){
            this.recordBrowseOwn = true;
            this.recordBrowseOwnApiUrl = manageOwn.apiUrl;
        }

      }
      
      //console.log("access record allowed details", this.recordBrowseAll, this.recordBrowseOwn, this.recordBrowseAssignedTo, this.recordBrowseBelongsTo, this.recordBrowseAllApiUrl, this.recordBrowseOwnApiUrl, this.recordBrowseAssignedToApiUrl, this.recordBrowseBelongsToApiUrl);

    },
  


    //BOC:[set filter via conditions]
    setFiltersViaConditions(conditions) {
      this.$refs.filter.setFiltersViaConditions(conditions);
    },
    //EOC [set filter via conditions]

    //BOC:[conditions]
    getConditions() {
      return this.conditions;
    },

    //EOC:[conditions]

    //BOC:[set conditions]
    setConditions(conditions) {
      //console.log("set conditions", conditions, 'selected Filter Conditions', this.selectedFilterConditions);
      this.selectedFilterConditions = conditions;
      this.conditions = conditions;
      this.$refs.tabulatorDataTable.getFilterCondition(conditions);
    },

    //EOC:[set conditions]

    //BOC:[set advanced filter conditions]
    setAdvancedFilterConditions(advanceFilterParams) {
      this.selectedAdvanceFilterConditions = advanceFilterParams;
      this.$refs.tabulatorDataTable.setAdvancedFilterConditions(
        advanceFilterParams
      );
    },

    //EOC:[set advanced filter conditions]

    //BOC:[apply column selection]
    applyColumnSelection(selectedColumns) {
      this.selectedCustomColums = selectedColumns;
      this.$refs.tabulatorDataTable.applyColumnSelection(selectedColumns);
    },

    //EOC:[apply column selection]

    //BOC:[get current page size]
    getCurrentPageSize(pagesize) {
      this.displayRowCount = pagesize;
    },
    //EOC:[get current page size]

    //BOC:[used to apply sort on the table]

    getCurrentSort(sorters) {
      this.cColumnSort = sorters;
      this.cColumnSort = sorters.map((sorter) => {
        return {
          column: sorter.field,
          dir: sorter.dir,
        };
      });
    },

    //EOC:[used to apply sort on the table]

    //BOC:[used to manage tables column width]

    getCurrentColumnWidth(resizedColumns) {
      const resizedColumnField = resizedColumns.getField();
      const resizedColumnWidth = resizedColumns.getWidth();

      //set the resized column width and filed name to the array

      const resizedColumnWidthAndField = {
        field: resizedColumnField,
        width: resizedColumnWidth,
      };

      const index = this.allResizeColumns.findIndex((item) => {
        return item.field === resizedColumnField;
      });

      if (index !== -1) {
        this.allResizeColumns[index].width = resizedColumnWidth;
      } else {
        this.allResizeColumns.push(resizedColumnWidthAndField);
      }
    },

    //EOC:[used to manage tables column width]

    //BOC:[following method is used to get the data based on the selected view]

    changeTableDetails(listAllViews, viewId) {
      // get the view details from listAllViews where id is equal to viewId
      if(viewId == "trashed"){
          //this.$refs.tabulatorDataTable.clearTableData();
          this.$refs.tabulatorDataTable.callApiUrl(this.apiGetBrowseTrashUrl());
          this.$refs.selectColumn.showAllColumns([]); 
          this.$refs.tabulatorDataTable.callSavedHeaderWidth([]);
          this.$refs.search.getFilterCondition([]);
          this.$refs.customView.changeSelectedTabStatus("trashed", 0);

      } else if(viewId == "all"){
        //this.$refs.tabulatorDataTable.clearTableData();
        this.$refs.tabulatorDataTable.callSavedHeaderWidth([]);
        this.$refs.selectColumn.showAllColumns([]);
        this.$refs.search.getFilterCondition([]);
        this.$refs.customView.changeSelectedTabStatus("all", 1);
        this.setCurrentPageTo();

      }else{
        //console.log("other", this.$refs.tabulatorDataTable.getCurrentApiUrl());
          const tabIndex = listAllViews.indexOf(listAllViews.find((x) => x.id === viewId)) + 2;
          this.$refs.customView.changeSelectedTabStatus(viewId, tabIndex);
          const singleViewDetail = listAllViews.filter((item) => {
            return item.id === viewId;
          });

        if (singleViewDetail.length > 0) {
          if(JSON.parse(singleViewDetail[0].manageColumnCustomWidth).length > 0){ 
            this.$refs.tabulatorDataTable.callSavedHeaderWidth(
                JSON.parse(singleViewDetail[0].manageColumnCustomWidth)
              );
          }

            this.$refs.selectColumn.showViewSelectedColumns(
              JSON.parse(singleViewDetail[0].cColumnCondition)
            );

          // calling API
          if(JSON.parse(singleViewDetail[0].filterCondition).length > 0){
            this.$refs.filter.setFiltersViaConditions(
              JSON.parse(singleViewDetail[0].filterCondition)
            ); 
          }

          // calling API
          if(JSON.parse(singleViewDetail[0].cColumnSort).length > 0){
            this.$refs.tabulatorDataTable.callSavedColumnSort(
              JSON.parse(singleViewDetail[0].cColumnSort)
            );
          }
            
          // calling API
          if(singleViewDetail[0].displayRowCount != this.paginationSize){
            this.$refs.tabulatorDataTable.callSavedPageSize(
              singleViewDetail[0].displayRowCount
            );
          }
          // filter records only in the specific view tab
          this.$refs.search.getFilterCondition(
            JSON.parse(singleViewDetail[0].filterCondition)
          );
          
        } 

          

        this.setCurrentPageTo();
      }

    },

    //EOC:[following method is used to get the data based on the selected view]

    //BOC:[using to get all view details based on the model key]
    getAllViews(viewId) {
      this.api.setMethod(`GET`);
      this.api.setUrl(
        `${this.$service.crm}/v1/en/console/crmView/list?modelKey=${this.model.key}`
      );

      this.api.setCallbackError(()=>{
        this.apiThatReturnedError = this.api
      });

      this.api.setCallbackCompleted((response) => {
        //this.listAllViews = response;
        // get latest view id from the listAllViews
        //const latestViewId = this.listAllViews[0].id;

        // get all private views when the ouwnerUuid is equal to auth.uuid

        const privateViews = response.filter((view) => {
          return view.isPrivate === true && view.ownerUuid === this.auth.uuid;
        });

        // get all public views
        const publicViews = response.filter((view) => {
          return view.isPrivate === false;
        });

        //console.log(viewId, tabIndex);

        // push all private and public views to listAllViews
        this.listAllViews = [...publicViews, ...privateViews];
        this.changeTableDetails(this.listAllViews, viewId);
      });
      this.api.fetch();
    },

    //EOC:[using to get all view details based on the model key]

    //BOC:[using to download the data in different format]
    downloadData(fileType, rowsType) {
      this.isLoading = true;

      if (rowsType == "selected") {
        // check if any row is selected or not
        if (this.$refs.tabulatorDataTable.tabulator.getSelectedData().length == 0) {
          this.isLoading = false;
          this.$store.dispatch("showMessage", {
                    message: "Please select at least one row to download",
                    messageType: "info",
                    timeout:2000
                });
          return;
        }
      } 

      

      switch (fileType) {
        case "csv":
          this.downloadCsvData(rowsType);
          break;
        case "xlsx":
          this.downloadExcelData(rowsType);
          break;
        case "pdf":
          this.downloadPdfData(rowsType);
          break;
        default:
          break;
      }
    },

    //EOC:[using to download the data in different format]

    //BOC:[using to download the data in csv format]
    downloadCsvData(rowsType) {
      this.$refs.tabulatorDataTable.tabulator.download(
        "csv",
        `${this.model.key}.csv`,
        {
          sheetName: `${this.model.key}`,
        },
        rowsType == "selected" ? "selected" : "all"
      );
      this.isLoading = false;
      // unchecked all the selected rows
      this.$refs.tabulatorDataTable.tabulator.deselectRow();
    },

    //EOC:[using to download the data in csv format]

    //BOC:[using to download the data in excel format]
    downloadExcelData(rowsType) {
      this.$refs.tabulatorDataTable.tabulator.download(
        "xlsx",
        `${this.model.key}.xlsx`,
        {
          sheetName: `${this.model.key}`,
        },
        rowsType == "selected" ? "selected" : "all"
      );
      this.isLoading = false;
      this.$refs.tabulatorDataTable.tabulator.deselectRow();
    },

    //EOC:[using to download the data in excel format]

    //BOC:[using to download the data in pdf format]
    downloadPdfData(rowsType) {
      this.$refs.tabulatorDataTable.tabulator.download(
        "pdf",
        `${this.model.key}.pdf`,
        {
          orientation: "portrait", //set page orientation to portrait
          title: "Demo Product Details", //add title to report
          autoTable: {
            //advanced table styling
            /* styles: {
              fillColor: [100, 255, 255], 
            },
            columnStyles: {
              id: { fillColor: 255 }, 
            },
            margin: { top: 60 }, */
          },
        },
        rowsType == "selected" ? "selected" : "all"
      );
      this.isLoading = false;
      this.$refs.tabulatorDataTable.tabulator.deselectRow();
    },

    //EOC:[using to download the data in pdf format]

    //BOC:[check if the cell is editable or not and set the cell editable status]

    setIsEditableCell(isEditable) {
      var viewId = this.$route.query.viewId;

      if (viewId != "all" && viewId != "trashed") {
        viewId = parseInt(viewId, 10);
        this.getEachViewStoredColumns(viewId, isEditable);
      } else {
        for (let column in this.columns) {
          if (this.columns[column].editable == !isEditable) {
            this.columns[column].editable = isEditable;
          }
        }
        this.$refs.tabulatorDataTable.tabulator.setColumns(this.columns);

        this.cellEditableStatus = !isEditable;
      }
    },

    //EOC:[check if the cell is editable or not and set the cell editable status]

    //BOC:[get each view stored columns and set the cell editable status true]

    getEachViewStoredColumns(viewId, isEditable) {
      this.api.setMethod("GET");
      this.api.setUrl(
        `${this.$service.crm}/v1/en/console/crmView/${viewId}/columns`
      );

      this.api.setCallbackError(()=>{
        this.apiThatReturnedError = this.api
      });

      this.api.setCallbackCompleted((response) => {
        var storedColumns = JSON.parse(response.cColumnCondition);

        var newColums = [];

        this.columns.forEach((column) => {
          if (storedColumns.includes(column.field)) {
            newColums.push(column);
          }
        });

        for (let column in newColums) {
          if (newColums[column].editable == !isEditable) {
            newColums[column].editable = isEditable;
          }
        }

        if (newColums.length > 0) {
          this.$refs.tabulatorDataTable.tabulator.setColumns(newColums);
        } else {
          for (let column in this.columns) {
            if (this.columns[column].editable == !isEditable) {
              this.columns[column].editable = isEditable;
            }
          }
          this.$refs.tabulatorDataTable.tabulator.setColumns(this.columns);
        }
        this.cellEditableStatus = !isEditable;
      });
      this.api.fetch();
    },

    //EOC:[get each view stored columns and set the cell editable status true]

    //BOC:[following method is used to update the cell data using tabulator emited event]
    updateEditedCellData(cell) {

      let editedRecordId = cell.getRow().getData().id;
      let editedRecordField = cell.getField();
      let editedRecordValue = cell.getValue();
      let editedRecordOldValue = cell.getOldValue();

      let columnDataType = cell.getColumn().getDefinition().dataType;

      if (columnDataType == "date") {
        //editedRecordValue = this.$moment(editedRecordValue).format("YYYY-MM-DD");
        editedRecordValue = new Date(editedRecordValue);
      }

      if (columnDataType == "dateWithBgColor") {
        //editedRecordValue = this.$moment(editedRecordValue).format("YYYY-MM-DD");
        editedRecordValue = new Date(editedRecordValue);
      }

      if (columnDataType == "modelDropdown") {
        let textColumn = cell.getColumn().getDefinition().textColumn;
        let apiServiceKey = cell.getColumn().getDefinition().apiServiceKey;
        let apiUrl = cell.getColumn().getDefinition().apiUrl;
        // return `${this.$service[this.service.key]}${this.apiBrowseUrl}`;

        this.apiDropdown.setMethod("GET");
        this.apiDropdown.setUrl(
          `${this.$service[apiServiceKey]}${apiUrl}/${cell.getValue()}`
        );

        this.apiDropdown.setCallbackError(()=>{
          this.apiThatReturnedError = this.apiDropdown
        });

        this.apiDropdown.setCallbackCompleted((response) => {
          let valueColumnData = response.name;
          editedRecordValue = valueColumnData;
          editedRecordField = textColumn;
          this.updateTableCellValue(
            editedRecordField,
            editedRecordValue,
            editedRecordId,
            editedRecordOldValue,
            cell
          );
        });
        this.apiDropdown.fetch();
      }

      if (
        this.service.key == "lead" &&
        editedRecordField == "status" &&
        editedRecordValue == "Contacted"
      ) {
        //console.log('contacted');
        this.addAccountContacts(cell);
      }

      if (
        this.service.key == "lead" &&
        editedRecordField == "timestampLocked"
      ) {
        console.log("Locked Date " + editedRecordValue);
      }

      // call cell update function
      /* if (columnDataType !== "modelDropdown") {
        this.updateTableCellValue(
          editedRecordField,
          editedRecordValue,
          editedRecordId,
          editedRecordOldValue,
          cell
        );
      }  */

      this.updateTableCellValue(
          editedRecordField,
          editedRecordValue,
          editedRecordId,
          editedRecordOldValue,
          cell
        );  
    },

    //EOC:[following method is used to update the cell data using tabulator emited event]

    //BOC:[following method is used to get the current page number]
    getCurrentPageNumber(pageno) {
      this.currentPageNo = pageno;
      //this.$store.commit("assignDeveloperData", { paginationPageNo: pageno });
    },

    //EOC:[following method is used to get the current page number]

    //BOC:[following method is used to set the current page number and store it in the store]
    setCurrentPageTo() {
      var pageNo = this.$route.query.page || 1;
      //var pageSize = this.$route.query.pageSize || 10;
      
      //this.$refs.tabulatorDataTable.tabulator.setPageSize(pageSize);
      this.$refs.tabulatorDataTable.tabulator.setPage(pageNo);
    },

    //EOC:[following method is used to set the current page number and store it in the store]

    //BOC:[following method is used update the cell value to database and validate it]

    updateTableCellValue(
      editedRecordField,
      editedRecordValue,
      editedRecordId,
      editedRecordOldValue,
      cell
    ) {
      var valid = cell.validate();

      if (valid == true) {
        if (editedRecordField !== undefined) {
          var responseData = {
            [editedRecordField]: editedRecordValue,
          };

          this.api.setMethod("POST");
          this.api.setParams({
            data: JSON.stringify(responseData),
          });

          if (this.apiMultiUpdateUrl != "") {
            this.api.setUrl(
              `${this.$service[this.service.key]}${
                this.apiMultiUpdateUrl
              }/${editedRecordId}/editDirectlyInTabulator`
            );
          } else {
            this.api.setUrl(
              `${this.$service[this.service.key]}/v1/en/console/tabulator/model/${
                this.model.key
              }/${editedRecordId}/editDirectlyInTabulator`
            );
          }

          this.api.setCallbackError(()=>{
            this.apiThatReturnedError = this.api
          });

          this.api.setCallbackCompleted(() => {
            //BOC:[modelDropdown]
            //make sure tabulator refresh the value after updated the data
            var columnDataType = cell.getColumn().getDefinition().dataType;
            if(columnDataType == "modelDropdown") cell.getRow().reformat();
            //EOC
            this.$store.dispatch("showMessage", {
                    message: "Updated successfully.",
                    messageType: "success",
                    timeout:2000
                });
          });

          /* this.api.setCallbackFailed(() => {
            this.$store.dispatch("showMessage", "Update failed.");
            cell.restoreOldValue();
          }); */

          //EOC:[using following tabulator method to update the cell value in the tabulator table]

          this.$refs.tabulatorDataTable.updateTableRowData(
            editedRecordId,
            editedRecordField,
            editedRecordValue
          );

          //EOC:[using following tabulator method to update the cell value in the tabulator table]

          this.api.fetch();

          if (this.service.key == "lead") {
            this.addLeadTimeLine(cell);
          }
        }
      } else {
        let validationType = valid[0].type;

        if (validationType == "unique") {
          this.$store.dispatch("showMessage", {
                    message: "This field is already exist.",
                    messageType: "error",
                    timeout:2000
                });
          
          cell.restoreOldValue();
          return;
        }

        if (validationType == "required") {
          this.$store.dispatch("showMessage", {
                    message: "This field is required.",
                    messageType: "error",
                    timeout:2000
                });
          cell.restoreOldValue();
          return;
        }

        if (validationType == "regex") {
          this.$store.dispatch(
            "showMessage",
            `Enter valid ${editedRecordField}`
          );
          cell.restoreOldValue();
          return;
        }

        if (validationType == "min") {
          this.$store.dispatch(
            "showMessage",
            `Minimum ${valid[0].parameters} characters required.`
          );
          cell.restoreOldValue();
          return;
        }

        if (validationType == "max") {
          this.$store.dispatch(
            "showMessage",
            `Maximum ${valid[0].parameters} characters allowed.`
          );
          cell.restoreOldValue();
          return;
        }

        if (validationType == "minLength") {
          this.$store.dispatch(
            "showMessage",
            `Maximum ${valid[0].parameters} characters allowed.`
          );
          cell.restoreOldValue();
          return;
        }

        if (validationType == "maxLength") {
          this.$store.dispatch(
            "showMessage",
            `Maximum ${valid[0].parameters} characters allowed.`
          );
          cell.restoreOldValue();
          return;
        }

        if (validationType == "numeric") {
          this.$store.dispatch(
            "showMessage",
            `Enter valid ${editedRecordField}`
          );
          cell.restoreOldValue();
          return;
        }

        if (validationType == "float") {
          this.$store.dispatch(
            "showMessage",
            `Enter valid ${editedRecordField}`
          );
          cell.restoreOldValue();
          return;
        }

        if (validationType == "integer") {
          this.$store.dispatch(
            "showMessage",
            `Enter valid ${editedRecordField}`
          );
          cell.restoreOldValue();
          return;
        }

        this.$store.dispatch("showMessage", {
                    message: "This field is required.",
                    messageType: "error",
                    timeout:2000
                });
        cell.restoreOldValue();
        return;
      }
    },

    //EOC:[following method is used update the cell value to database and validate it]

    //BOC:[following method is used to store the contact details]

    addAccountContacts(cell) {
      /**
       * id, uuid,leadUuid, company, name, position, email, phone, language, timestampLocked, timestampCreated, timestampUpdated
       * timestampDeleted
       */
      var leadUuid = cell.getRow().getData().uuid;
      var leadName = cell.getRow().getData().name;
      var leadCompany = cell.getRow().getData().company;
      var leadPosition = cell.getRow().getData().position;
      var leadEmail = cell.getRow().getData().email;
      var leadPhone = cell.getRow().getData().phone;
      var leadLanguage = cell.getRow().getData().language;

      this.api.setMethod("POST");
      this.api.setParams({
        data: JSON.stringify({
          leadUuid: leadUuid,
          company: leadCompany,
          name: leadName,
          position: leadPosition,
          email: leadEmail,
          phone: leadPhone,
          language: leadLanguage,
        }),
      });
      this.api.setUrl(
        `${this.$service[this.service.key]}/v1/en/console/tabulator/model/accountContact/add`
      );

      this.api.setCallbackError(()=>{
            this.apiThatReturnedError = this.api
          });

      this.api.setCallbackCompleted(() => {
        this.$store.dispatch("showMessage", {
                    message: "Contact created successfully.",
                    messageType: "success",
                    timeout:2000
                });
      });

      this.api.fetch();
    },

    //EOC:[following method is used to store the contact details]

    //BOC:[following method is used to store the lead timeline]
    addLeadTimeLine(cell) {
      /**
       * id, leadUuid, leadId, efectedColumn, oldValue, newValue, timestampCreated, salespersonInternalUserUuid(loged user uuid),
       * salespersonInternalUserName (loged user name)
       */

      var leadUuid = cell.getRow().getData().uuid;
      var leadId = cell.getRow().getData().id;
      var efectedColumn = cell.getField();
      var oldValue = cell.getOldValue();
      var newValue = cell.getValue();
      var salespersonInternalUserUuid = this.auth.uuid;
      var salespersonInternalUserName = this.auth.name;

      var responseData = {
        leadUuid: leadUuid,
        leadId: parseInt(leadId, 10), ///set to int type in db
        efectedColumn: efectedColumn,
        oldValue: oldValue,
        newValue: newValue,
        salespersonInternalUserUuid: salespersonInternalUserUuid,
        salespersonInternalUserName: salespersonInternalUserName,
      };

      this.apiMangeTimeLine.setMethod("POST");
      this.apiMangeTimeLine.setParams({
        data: JSON.stringify(responseData),
      });

      this.apiMangeTimeLine.setUrl(
        `${
          this.$service[this.service.key]
        }/v1/en/console/tabulator/model/leadTimeline/add`
      );

      this.apiMangeTimeLine.setCallbackError(()=>{
            this.apiThatReturnedError = this.apiMangeTimeLine
          });

      this.apiMangeTimeLine.setCallbackCompleted(() => {
        //
      });

      this.apiMangeTimeLine.fetch();
    },

    //EOC:[following method is used to store the lead timeline]

    getSelectedRows(data) {
      this.selectedRows = data;
    },


    getDataLoadErrorCode(errorCode){
      this.dataLoadErrorCode = errorCode;
    }

  },
};
</script>
<style>
.btn-primary {
  background-color: #1976d2;
  color: #fff;
  border-radius: 4px;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
}

.btn-delete-hard {
  background-color: #ff0000;
  color: #fff;
  border-radius: 4px;
  padding: 7px 10px;
  border: none;
  cursor: pointer;
}
</style>