<template>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
        :disabled="!disabledStatus || selectedRows.length === 0 || !model.leadMultiAssign"
        v-bind="attrs" v-on="on">Lead Assignment </v-btn>
      </template>
      <v-card>
        
        <v-card-title>
          <span class="text-h5">Multiple Lead Assignment</span>
        </v-card-title>
        <v-form ref="leadAssignment" lazy-validation @submit.prevent="handleLeadAssignment">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="12">
                    <template v-for="(field, index) in model.add.fields">
                    <template v-if="field.type =='modelParent'">
                        <BreadTabulatorFormParentSelectModel
                        :form="form"
                        :key="index"
                        :field="field"
                        @inputParent="form.data[field.parentKey] = $event"
                        @getParentSelectedItem="getParentSelectedItem"
                        ></BreadTabulatorFormParentSelectModel>
                    </template>

                    <template v-else-if="field.type =='modelChild' && field.isDisplay == true ">
                        <BreadTabulatorFormChildSelectModel
                        :form="form"
                        :key="index"
                        :field="field"
                        @inputchild="form.data[field.childKey] = $event"
                        :parentSelectedValue = "parentSelectedValue"
                        ></BreadTabulatorFormChildSelectModel>
                    </template>

                </template>

                </v-col>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
            <p class="error-message">{{ apiErrorMessage }}</p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">
              Close
            </v-btn>
            <v-btn color="blue darken-1" text @click="handleLeadAssignment">
              Assign Selected Lead
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </template>

<script>

import Api from "@/objects/api";
import { mapState } from "vuex";
import Form from "@/objects/form";
import Service from "@/objects/service";

import BreadTabulatorFormParentSelectModel from "@/components/common/BreadTabulatorFormParentSelectModel.vue";
import BreadTabulatorFormChildSelectModel from "@/components/common/BreadTabulatorFormChildSelectModel.vue";

export default {
name: "LeadAssignToInternalUsers",
  data() {
    return {
      dialog: false,
      api : new Api(),
      parentSelectedValue: null,
      form: new Form(),
      service: new Service(),
      apiErrorMessage: "",
    };
  },

  computed: mapState({
    auth: (state) => state.auth.data,
    services: (state) => state.service.data.services,
    rolePermission: (state) => state.rolePermission.data,
    team: (state) => state.team.data,
  }),

  components: {
    BreadTabulatorFormParentSelectModel,
    BreadTabulatorFormChildSelectModel,
  },

  props: {
    model: {
      type: Object,
      required: true,
    },
    selectedRows: {
      type: Array,
      required: true,
    },
    disabledStatus: {
      type: Boolean,
    },
  },

  created() {
    //
  },

  methods: {

    getParentSelectedItem(parentItem) {
      this.parentSelectedValue = parentItem;
    },

    handleLeadAssignment() {
    
        // get selected rows
        // assign internal user to selected rows
        // close dialog
        // refresh table
        //this.$service['lead']

        var selectedInternalUserUuid = this.form.data.salespersonInternalUserUuid;
        var selectedInternalUserName = this.form.data.salespersonInternalUserName;
        var selectedTeamUuid = this.form.data.ssoTeamUuid;
        var selectedTeamName = this.form.data.ssoTeamName; 
        var selectedLeadIds = this.selectedRows.map((row) => row.id);

        this.api.setMethod(`POST`);
        this.api.setUrl(`${this.$service['lead']}/v1/en/console/model/lead/multipleLeadAssignToInternalUser`);
        this.api.setParams({
          leadIds: selectedLeadIds,
          salespersonInternalUserUuid: selectedInternalUserUuid,
          salespersonInternalUserName: selectedInternalUserName,
          ssoTeamUuid: selectedTeamUuid,
          ssoTeamName: selectedTeamName,
        });

        this.api.setCallbackError(()=>{
            this.apiErrorMessage = 'Error in assigning lead to internal user';
        });

        this.api.setCallbackCompleted(() => {
          this.$store.dispatch("showMessage", {
                    message: "Assign Lead to Internal User Successful!",
                    messageType: "success",
                    timeout:2000
                });
          this.dialog = false;
          this.$router.go();
        });
        this.api.fetch();
       
    },

    
  },
};


</script>

<style>
.error-message{
    color: red;
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
}

</style>